
import * as bootstrap from 'bootstrap'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import CustomerContactEntity from "../entity";
export default class CustomerContactShow extends DefaultController {
    async init() {
        this.entity = "customer_contacts";
        await super.init();
    }

    protected getEntityData(elem: any) {
        return CustomerContactEntity.getEntityData(elem)
    }
    bindListeners() {
        (document.querySelector("#editContactForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editContactForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.getEntity()
                    const bsElem = bootstrap.Modal.getInstance((document.querySelector("#editContact") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success("Kontakt wurde erfolgreich aktualisiert.", "Erfolg!")
                }
            }
        });
    }
}